<template>
  <div class="form-check form-switch">
    <input
      class="form-check-input"
      type="checkbox"
      :value="initValue"
      v-model="value"
      @input="onChanged"
      :data-label="value ? $t('YES') : $t('NO')"
      :disabled="disabled"
    />
  </div>
</template>

<script>
export default {
  name: "Switch",
  props: ["initValue", "disabled"],
  emits: ["changed"],
  methods: {
    onChanged($event) {
      this.$emit("changed", $event.target.checked);
    },
  },
  computed: {
    value: {
      get() {
        return this.initValue ? this.initValue : false;
      },
      set() {},
    },
  },
};
</script>

<style lang="scss" scoped>
.form-switch {
  position: relative;
  padding-left: 3em;

  input {
    width: 3rem;
    height: 1.2rem;
    position: relative;

    &:checked:before {
      content: attr(data-label);
      position: absolute;
      left: 0.4rem;
      color: white;
      font-size: 0.8rem;
    }

    &:after {
      content: attr(data-label);
      position: absolute;
      right: 0.4rem;
      color: gray;
      font-size: 0.8rem;
    }

    &:checked:after {
      display: none;
    }
  }
}
</style>
